// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

const MemberCreate = () => {
  const navigate = useNavigate();

  // hooks
  const { control, handleSubmit, formState } = useForm();

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("member", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/member");
        toast.success("Member created!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    data.type = data.type.join(",");
    crateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Member
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="name"
              render={({ field }) => (
                <InputText
                  placeholder="Input name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="type">Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({ field }) => (
                <MultiSelect
                  className={classNames({ "p-invalid": formState.errors?.type }, "w-full")}
                  emptyFilterMessage="no data found"
                  value={field.value}
                  options={[
                    { label: "Count", value: 1 },
                    { label: "Amount", value: 2 },
                  ]}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select type"
                  display="chip"
                />
              )}
            />
            {formState.errors?.type && (
              <small id="type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="transaction_count">Transaction Count : </label>
          <Controller defaultValue={0} control={control} name="transaction_count" render={({ field }) => <InputNumber className={classNames("w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
        </div>
        <div className="field col-12">
          <label htmlFor="transaction_amount">Transaction Amount : </label>
          <Controller defaultValue={0} control={control} name="transaction_amount" render={({ field }) => <InputNumber className={classNames("w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
        </div>
        <div className="field col-12">
          <label htmlFor="member_type">Member Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="member_type"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  className={classNames({ "p-invalid": formState.errors?.member_type }, "w-full")}
                  emptyFilterMessage="no data found"
                  value={field.value}
                  options={[
                    { label: "Regular", value: 0 },
                    { label: "Vip", value: 1 },
                  ]}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select member type"
                  display="chip"
                />
              )}
            />
            {formState.errors?.member_type && (
              <small id="member_type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="early_access">Early Access : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="early_access" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/member">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(MemberCreate, comparisonFn);
