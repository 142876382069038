// hooks
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
// import { DevTool } from "@hookform/devtools";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// utils
import { useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import NewTimeFormatter from "../../utils/NewTimeFormatter";

const StaticPage = ({ permissions }) => {
  const navigate = useNavigate();

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);

  // api calling
  const { data: pagesData, isLoading, isError, isSuccess, refetch } = useQuery("static-page", async () => await getStaticPage());

  // query
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("static", { data }), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Page Deleted!", { duration: 4000 });
      } catch (error) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getStaticPage = async () => {
    try {
      const response = await Api().get("static");

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      return response.data.data;
    } catch (error) {
      toast.error(error.message || "an error occured while getting static page");
    }
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      static_page_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.static_page_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      static_page_id: [data._id],
    };

    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  // life cycle
  useEffect(() => {
    if (!isLoading && isSuccess) {
      let temp_data = [];

      for (let i = 0; i < pagesData.length; i++) {
        temp_data.push(pagesData[i]);
      }

      setPages(temp_data);
    }
  }, [isLoading, pagesData, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Please check your connections", { duration: 6000 });
    }
  }, [isError]);

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => navigate("/dashboard/static-page/create")} />}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/static-page/update/${rowData?._id}`)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Static Page</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={pages}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Page found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "1rem" }}></Column>
              <Column field="page_title" header="Page Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="route" header="Route Path" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="updated_at" body={(data) => NewTimeFormatter(data.created_at)} header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Pages?</span>}
        </div>
      </Dialog>

      {/* <DevTool control={updateForm.control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(StaticPage, comparisonFn);
