import { useQuery } from "react-query";
import QuizAnswerApi from "../api/QuizAnswerApi";

export const useGetAnswers = () => {
  const getAnswer = async () => {
    const response = await QuizAnswerApi.Get();

    if (response.data.status !== 200) {
      throw new Error("An error has occured!");
    }

    return response.data.data;
  };

  return useQuery("quiz-answers", () => getAnswer());
};
