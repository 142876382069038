// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import ReactImageUploading from "react-images-uploading";
import { Image } from "primereact/image";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { useState } from "react";

const UpdateHomePage = () => {
  const home_id = process.env.REACT_APP_HOME_ID;
  const [imagesSection3, setImagesSection3] = useState([]);
  const [imagesSection6, setImagesSection6] = useState([]);

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { data: productOption } = useQuery("product", () => fetchHandler("product"), { initialData: [] });
  const { data: homeTestimonyOptions } = useQuery("home-Testimony", () => fetchHandler("home-testimony"), { initialData: [] });
  const { data: bannerOption } = useQuery("home-banner", () => fetchHandler("home-banner"), { initialData: [] });
  const { data: journalsOptions } = useQuery("journal", () => fetchHandler("journal"), { initialData: [] });
  const { refetch: refetchHomePage } = useQuery("home-page", () => getHomePageDetails());
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/home/page/edit", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        refetchHomePage();
        toast.success("Success updated!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getHomePageDetails = async () => {
    try {
      const res = await Api().get("/home/detail?home_id=" + home_id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      // mapping
      let banner_top = [];
      let banner = [];
      let section2_products = [];
      let temp_testimonies = [];
      let temp_images_section_3 = [];
      let temp_images_section_6 = [];
      let journals = [];
      let result = res.data.data;

      if (result.testimonies?.length) {
        result.testimonies.map((d) => temp_testimonies.push(d._id));
      }
      if (result.journals?.length) {
        result.journals.map((d) => journals.push(d._id));
      }
      if (result.banner?.length) {
        result.banner.map((d) => banner.push(d._id));
      }
      if (result.top_banner?.length) {
        result.top_banner.map((d) => banner_top.push(d._id));
      }

      if (result.section2_products?.length) {
        result.section2_products.map((d) => section2_products.push(d._id));
      }

      if (result.section3_images?.length) {
        for (let i = 0; i < result.section3_images.length; i++) {
          temp_images_section_3.push({ data_url: result.section3_images[i].url, file: result.section3_images[i] });
        }
      }
      if (result.section6_images?.length) {
        for (let i = 0; i < result.section6_images.length; i++) {
          temp_images_section_6.push({ data_url: result.section6_images[i].url, file: result.section6_images[i] });
        }
      }

      result.testimonies = temp_testimonies;
      setImagesSection3(temp_images_section_3);
      setImagesSection6(temp_images_section_6);
      reset({ ...result, banner, section2_products, journals, top_banner: banner_top });
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const fetchHandler = async (endpoint) => {
    try {
      const res = await Api().get(endpoint);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const onChangeImageSection3 = (imageList) => {
    setImagesSection3(imageList);
  };

  const onChangeImageSection6 = (imageList) => {
    setImagesSection6(imageList);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    let file_key = ["files_thumbnail", "files", "files_mobile", "section3_files", "section6_files"];

    // if data is array then its gonna looop
    let array_key = ["no"];

    data.home_id = home_id;
    data.journals = JSON.stringify(data.journals || []);
    data.testimonies = JSON.stringify(data.testimonies || []);
    data.section2_products = JSON.stringify(data.section2_products || []);
    data.banner = JSON.stringify(data.banner || []);
    data.top_banner = JSON.stringify(data.top_banner || []);

    // image section 3
    if (imagesSection3.length) {
      // prev images
      let temp_prev_images = [];

      // new images
      let temp_image = [];

      for (let i = 0; i < imagesSection3.length; i++) {
        // this mean its a prev image
        if (imagesSection3[i].data_url.includes("https")) {
          temp_prev_images.push(imagesSection3[i].file);
        } else {
          temp_image.push(imagesSection3[i].file);
        }
      }

      if (temp_prev_images.length) {
        data.section3_images = JSON.stringify(temp_prev_images);
      } else {
        delete data.section3_images;
      }

      if (temp_image.length) {
        data.section3_files = temp_image;
      }
    }

    // image section 6
    if (imagesSection6.length) {
      // prev images
      let temp_prev_images = [];

      // new images
      let temp_image = [];

      for (let i = 0; i < imagesSection6.length; i++) {
        // this mean its a prev image
        if (imagesSection6[i].data_url.includes("https")) {
          temp_prev_images.push(imagesSection6[i].file);
        } else {
          temp_image.push(imagesSection6[i].file);
        }
      }

      if (temp_prev_images.length) {
        data.section6_images = JSON.stringify(temp_prev_images);
      } else {
        delete data.section6_images;
      }

      if (temp_image.length) {
        data.section6_files = temp_image;
      }
    }

    if (!data.related?.length) {
      delete data.related;
    }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }

    crateMutate(formData);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Homepage Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0]?.url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  const JournalSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.title} src={option?.images[0]?.url} />
        <div className="ml-2">{option.title}</div>
      </div>
    );
  };

  const bannerTemplateOption = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width="100" src={option?.images[0]?.url} />
        <div className="pl-4">{option.title}</div>
      </div>
    );
  };

  const HomeTestimonySelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Image style={{ backgroundColor: "black" }} width="100" src={option?.images[0]?.url} />
        <div className="pl-4">{option.title.slice(0, 10)}</div>
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Section 1</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Top Text : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={""}
              name="top_text"
              render={({ field }) => (
                <InputText
                  placeholder="Input top text"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.top_text,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.top_text && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="top_text_url">Top Text Url : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={""}
              name="top_text_url"
              render={({ field }) => (
                <InputText
                  placeholder="Input top text url"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.top_text_url,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.top_text_url && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="top_banner">Home Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="top_banner"
              render={({ field }) => (
                <MultiSelect
                  itemTemplate={bannerTemplateOption}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!bannerOption?.length}
                  value={field.value}
                  options={bannerOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select banner"
                  display="chip"
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images: </label>
          <ReactImageUploading style={{ width: "100%" }} value={imagesSection6} onChange={onChangeImageSection6} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>

        <div className="col-12">
          <p className="font-bold uppercase">Section 2</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_products">Product : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section2_products"
              render={({ field }) => (
                <MultiSelect
                  className="w-full"
                  itemTemplate={productSelectTemplate}
                  emptyFilterMessage="no data found"
                  disabled={!productOption?.length}
                  value={field.value}
                  options={productOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select product"
                  display="chip"
                />
              )}
            />
            {formState.errors?.section2_products && (
              <small id="section2_products" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section2_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section2_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section2_description && (
              <small id="section2_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 3</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="banner">Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="banner"
              render={({ field }) => (
                <MultiSelect
                  itemTemplate={bannerTemplateOption}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!bannerOption?.length}
                  value={field.value}
                  options={bannerOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select banner"
                  display="chip"
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 3 Product Highlight</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section3_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              name="section3_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section3_description && (
              <small id="section3_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images: </label>
          <ReactImageUploading style={{ width: "100%" }} value={imagesSection3} onChange={onChangeImageSection3} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="col-12">
          <p className="font-bold uppercase">Section 4</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section4_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section4_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section4_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section4_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="testimonies">Testimony : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="testimonies"
              render={({ field }) => (
                <MultiSelect
                  className="w-full"
                  itemTemplate={HomeTestimonySelectTemplate}
                  emptyFilterMessage="no data found"
                  disabled={!homeTestimonyOptions?.length}
                  value={field.value}
                  options={homeTestimonyOptions}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select Testimony"
                  display="chip"
                />
              )}
            />
            {formState.errors?.testimonies && (
              <small id="testimonies" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 5</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="journals">Journal : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="journals"
              render={({ field }) => (
                <MultiSelect
                  className="w-full"
                  itemTemplate={JournalSelectTemplate}
                  emptyFilterMessage="no data found"
                  disabled={!journalsOptions?.length}
                  value={field.value}
                  options={journalsOptions}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select product"
                  display="chip"
                />
              )}
            />
            {formState.errors?.journals && (
              <small id="journals" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section5_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section5_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section5_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section5_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateHomePage, comparisonFn);
