import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useState } from "react";
import logo from "../assets/images/logo.svg";

const AppMenuItem = ({ item, activeRoute, setActiveRoute }) => {
  return (
    <NavLink
      onClick={() => {
        setActiveRoute(item.to);
      }}
      className={classNames(
        {
          "menu-item-active": activeRoute === item.to,
        },
        "flex align-items-center menu-item"
      )}
      role="menuitem"
      to={item.to}
    >
      <i class="fa-solid fa-circle" style={{ fontSize: "5px" }}></i>
      <span style={{ marginLeft: "8px" }}>{item.label}</span>
    </NavLink>
  );
};

export const AppMenu = (props) => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  return (
    <div className="layout-menu-container">
      <div className="menu-logo">
        <Link to="/">
          <div className="align-items-center h-2rem md:h-3rem ">
            <img className="w-full h-full" src={logo} alt="logo" />
          </div>
        </Link>
      </div>
      <Accordion>
        {props.model.map((menu) => (
          <AccordionTab className="menu" header={menu.label} key={menu.label}>
            {menu.items.map((item, i) => (
              <AppMenuItem key={item.label} index={i} item={item} activeRoute={activeRoute} setActiveRoute={setActiveRoute} />
            ))}
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
};
