// hooks
import React, { useEffect } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { AutoComplete } from "primereact/autocomplete";
import ReactImageUploading from "react-images-uploading";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

// utils
import classNames from "classnames";
import basicJodithConfig from "../../config/basicJodithConfig";
import JoditEditor from "jodit-react";

// api related
import Api from "../../api/Api";
import { useState } from "react";
import variantColorOptions from "../../assets/data/variant_color_options.json";
import variantSizeOptions from "../../assets/data/variant_size_options.json";
import moment from "moment";

const variantTypeOptions = [
  { value: "Color", label: "Color" },
  { value: "Size", label: "Size" },
];

const ProductCreate = () => {
  const navigate = useNavigate();
  const [isVariant, setIsVariant] = useState(false);
  const [colorOptions, setColorOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [variantWatch, setVariantWatch] = useState([]);
  const [variantField, setVariantField] = useState([]);
  const [variantCombination, setVariantCombination] = useState([]);
  const [images, setImages] = useState([]);
  const [imageCustom, setImageCustom] = useState([]);
  const [selectedUOM, setSelectedUOM] = useState();

  // hooks
  const { control, handleSubmit, formState, register, watch } = useForm();
  const is_freebie_watch = watch("is_freebies");

  const {
    fields: variantOneFields,
    remove: variantOneRemove,
    append: variantOneAppend,
  } = useFieldArray({
    control,
    name: "variant",
  });
  const {
    fields: claimFields,
    remove: claimFieldRemove,
    append: claimFieldAppend,
  } = useFieldArray({
    control,
    name: "custom_text",
  });
  const {
    fields: faqFields,
    remove: faqFieldsRemove,
    append: faqFieldsAppend,
  } = useFieldArray({
    control,
    name: "content_section3",
  });
  const variants_watch = useWatch({
    control,
    name: "variant",
  });

  // query
  const { data: productOption } = useQuery("product", () => getProduct());
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });
  const { data: productIngridientOption } = useQuery("product-ingridient", () => getProductIngridient(), { initialData: [] });
  const { data: uomOptions } = useQuery("uom", () => getUomOption(), { initialData: [] });

  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("product", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/product");
        toast.success("Product created!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getProduct = async () => {
    try {
      const res = await Api().get("/product?freebies=false");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getProductCategory = async () => {
    try {
      const res = await Api().get("/category/option");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getProductIngridient = async () => {
    try {
      const res = await Api().get("/product-ingredient");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getUomOption = async () => {
    try {
      const res = await Api().get("/uom");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onChangeImage = (imageList) => {
    setImages(imageList);
  };

  const onChangeImageCustom = (imageList) => {
    setImageCustom(imageList);
  };

  const searchColorOption = (event) => {
    let results;

    if (!event.query.trim().length) {
      results = [...variantColorOptions];
    } else {
      results = variantColorOptions.filter((color) => {
        return color.toLowerCase().startsWith(event.query.toLowerCase());
      });

      if (!variantColorOptions.find((color) => color.toLocaleLowerCase() === event.query.toLocaleLowerCase())) {
        results.push(event.query.charAt(0).toUpperCase() + event.query.slice(1));
      }
    }

    setColorOptions(results);
  };

  const searchSizeOption = (event) => {
    let results;

    if (!event.query.trim().length) {
      results = [...variantSizeOptions];
    } else {
      results = variantSizeOptions.filter((size) => {
        return size.toLowerCase().startsWith(event.query.toLowerCase());
      });

      if (!variantSizeOptions.find((size) => size.toLocaleLowerCase() === event.query.toLocaleLowerCase())) {
        results.push(event.query.charAt(0).toUpperCase() + event.query.slice(1));
      }
    }

    setSizeOptions(results);
  };

  const onSubmit = (data) => {
    if (data.launching_date?.length) {
      let start_date = data.launching_date[0];
      let end_date = data.launching_date[1] || start_date;

      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");

      data.show_start_date = start_date;
      data.show_end_date = end_date;
    } else {
      data.show_start_date = "";
      data.show_end_date = "";
    }

    if (data.is_freebies) {
      data.promo_price = 0;
      data.custom_text = [];
      data.detail = "";
      data.content_text1 = "";
      data.content_text2 = "";
      data.content_text3 = "";
      data.variant = [];
      data.ingredients = [];
    }
    const text_keys = ["content_text1", "content_text2", "content_text3", "short_description", "detail"];

    for (const key in data) {
      if (text_keys.includes(key)) {
        if (data[key] === undefined || data[key] === null || data[key] === "") {
          delete data[key];
        }
      }
    }

    //
    const formData = new FormData();
    let file_key = ["files_thumbnail", "files", "files_custom", "files_mobile"];

    // if data is array then its gonna looop
    let array_key = ["files_thumbnail", "files", "files_custom", "files_mobile"];

    data.related = JSON.stringify(data.related);
    data.category_id = JSON.stringify(data.category_id);
    data.custom_text = JSON.stringify(data.custom_text);
    data.content_section3 = JSON.stringify(data.content_section3);
    data.ingredients = JSON.stringify(data.ingredients || []);

    // to filter variant if has value if not remove
    data.variant = variantField.filter((v) => v.details.length > 0);

    if (variantField.length) {
      data.variant = JSON.stringify(data.variant);
      data.variant_detail = JSON.stringify(variantCombination);
    } else {
      data.variant = JSON.stringify([]);
      data.variant_detail = JSON.stringify([]);
    }

    if (!images.length) return toast.error("Please Upload Images");
    if (images.length) {
      let temp_image = [];
      for (let i = 0; i < images.length; i++) {
        temp_image.push(images[i].file);
      }
      data.files = temp_image;
    }

    if (imageCustom.length) {
      let temp_image = [];
      for (let i = 0; i < imageCustom.length; i++) {
        temp_image.push(imageCustom[i].file);
      }
      data.files_custom = temp_image;
    }

    if (!data.related?.length) {
      delete data.related;
    }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }

    crateMutate(formData);
  };

  const hanldeErrorImageUpload = (error) => {
    if (error?.acceptType) {
      toast.error("format image should jpg, jpeg, png");
    }

    if (error?.maxFileSize) {
      toast.error("File Size Max 1MB");
    }
    if (error?.maxNumber) {
      toast.error("max file is 8 images");
    }
  };

  // components
  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0].url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Product
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    claimFieldAppend();
    faqFieldsAppend();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (variants_watch) {
      let filtred_variant = variants_watch.filter((v) => v.type !== "" && v.value !== "");

      let color_variant = filtred_variant.find((v) => v.type === "Color")?.value || [];
      let size_variant = filtred_variant.find((v) => v.type === "Size")?.value || [];

      let first_array = color_variant.length >= size_variant.length ? color_variant : size_variant;
      let second_array = color_variant.length < size_variant.length ? color_variant : size_variant;

      // mapping for variant color
      let temp_variant_field_color = {
        name: "Color",
        details: [],
      };

      for (let i = 0; i < color_variant.length; i++) {
        temp_variant_field_color.details.push(color_variant[i]);
      }

      // mapping for variant size
      let temp_variant_field_size = {
        name: "Size",
        details: [],
      };

      for (let i = 0; i < size_variant.length; i++) {
        temp_variant_field_size.details.push(size_variant[i]);
      }

      setVariantField([temp_variant_field_color, temp_variant_field_size]);

      let array_comb = [];

      for (var i = 0; i < first_array.length; i++) {
        if (second_array.length) {
          for (var j = 0; j < second_array.length; j++) {
            array_comb.push({
              id: i + j * 120,
              variant: [first_array[i], second_array[j]],
              price: 0,
              qty: 0,
              image_index: 0,
            });
          }
        } else {
          array_comb.push({
            id: i * 120,
            variant: [first_array[i]],
            price: 0,
            qty: 0,
            image_index: 0,
          });
        }
      }

      setVariantCombination(array_comb);
    }
  }, [variants_watch]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Product Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="name"
              render={({ field }) => (
                <InputText
                  placeholder="Input product name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="sku">Product SKU : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="sku"
              render={({ field }) => (
                <InputText
                  placeholder="Input product sku"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.sku,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.sku && (
              <small id="sku" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-4">
          <label htmlFor="category_id">Category : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="category_id"
              rules={{ required: true }}
              render={({ field }) => (
                <MultiSelect
                  className={classNames({ "p-invalid": formState.errors?.category_id }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!catData?.length}
                  value={field.value}
                  options={catData}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select category"
                  display="chip"
                />
              )}
            />
            {formState.errors?.category_id && (
              <small id="category_id" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-4">
          <label htmlFor="ingredients">Ingredients : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="ingredients"
              rules={{ required: false }}
              render={({ field }) => (
                <MultiSelect
                  className={classNames({ "p-invalid": formState.errors?.ingredients }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!catData?.length}
                  value={field.value}
                  options={productIngridientOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select category"
                  display="chip"
                />
              )}
            />
            {formState.errors?.ingredients && (
              <small id="ingredients" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-4">
          <label htmlFor="related">Related Product : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="related"
              render={({ field }) => (
                <MultiSelect
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!productOption?.length}
                  value={field.value}
                  options={productOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select related product"
                  display="chip"
                  itemTemplate={productSelectTemplate}
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="is_freebies">For Freebies : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="is_freebies" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="meta_title">Product Active Status : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="meta_title">Show In Homepage : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="show_homepage" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Product Pricing and other</p>
        </div>

        {/* price */}
        <div className="field col-12">
          <label htmlFor="price">Price (IDR) : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="price"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.price && (
            <small id="price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>

        {/* price */}
        {!is_freebie_watch ? (
          <div className="field col-12">
            <label htmlFor="promo_price">Promo Price (IDR) : </label>
            <Controller
              defaultValue={0}
              control={control}
              name="promo_price"
              render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
            />
            {formState.errors?.promo_price && (
              <small id="promo_price" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        ) : null}

        {/* qty */}
        <div className="field col-12">
          <label htmlFor="qty">Qty : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="qty"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.qty }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.qty && (
            <small id="qty" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>

        {/* qty alert */}
        <div className="field col-12">
          <label htmlFor="qty_alert">Qty Alert : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="qty_alert" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
            {formState.errors?.qty_alert && (
              <small id="qty_alert" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        {/* uom */}
        <div className="field col-12 ">
          <label htmlFor="uom">Unit of Measurement (UOM) : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="uom"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  className={classNames({ "p-invalid": formState.errors?.uom }, "w-full")}
                  emptyFilterMessage="no data found"
                  disabled={!uomOptions?.length}
                  value={field.value}
                  options={uomOptions}
                  onChange={(e) => {
                    field.onChange(e);
                    const selected_uom = uomOptions.find((uom) => uom._id === e.value);
                    setSelectedUOM(selected_uom?.name || "-");
                  }}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select category"
                />
              )}
            />
            {formState.errors?.uom && (
              <small id="uom" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        {/* weight */}
        <div className="field col-12">
          <label htmlFor="weight">Weight {selectedUOM ? `(${selectedUOM})` : ""} : </label>
          <div className="w-full">
            <Controller defaultValue={0} control={control} name="weight" render={({ field }) => <InputNumber disabled={!selectedUOM} className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
            {formState.errors?.weight && (
              <small id="weight" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Product Descriptions</p>
        </div>
        {!is_freebie_watch ? (
          <div className="field col-12">
            <label htmlFor="description">Product Claims : </label>
            {claimFields.map((item, index) => (
              <div className="mt-2" key={item.id}>
                <div className="p-fluid formgrid grid ">
                  <div className="field col-12 lg:col-10">
                    <label htmlFor="user">Claim :</label>
                    <InputText placeholder="Input page name" {...register(`custom_text[${index}]`)} id="name" type="text" />
                  </div>
                  <div className="field col-12 lg:col-2">
                    <label className="block"></label> <br />
                    <Button style={{ width: "100%" }} icon="pi pi-times block w-full" onClick={() => claimFieldRemove(index)} className=" p-button-danger mr-4" />
                  </div>
                </div>
              </div>
            ))}
            <div style={{ padding: 0 }} className=" col-12 lg:col-2">
              <Button type="button" style={{ width: "100%" }} onClick={() => claimFieldAppend()} label="Add Claim Field" />
            </div>
          </div>
        ) : null}

        {/* short_description */}
        <div className="field col-12">
          <label htmlFor="short_description">Product Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: false }}
              control={control}
              name="short_description"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {formState.errors?.short_description && (
              <small id="short_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>

        {/* Details */}
        {!is_freebie_watch ? (
          <div className="field col-12">
            <label htmlFor="detail">Detail : </label>
            <div className="w-full">
              <Controller
                rules={{ required: false }}
                control={control}
                name="detail"
                render={({ field }) => (
                  <JoditEditor
                    ref={field.ref}
                    value={field.value || ""}
                    config={basicJodithConfig}
                    tabIndex={1}
                    onBlur={(e) => {
                      field.onBlur(e);
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              {formState.errors?.detail && (
                <small id="detail" className="p-error block pt-1">
                  filed required
                </small>
              )}
            </div>
          </div>
        ) : null}

        {!is_freebie_watch ? (
          <>
            {/* how to use */}
            <div className="field col-12">
              <label htmlFor="content_text1">How to use : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: false }}
                  control={control}
                  name="content_text1"
                  render={({ field }) => (
                    <JoditEditor
                      ref={field.ref}
                      value={field.value || ""}
                      config={basicJodithConfig}
                      tabIndex={1}
                      onBlur={(e) => {
                        field.onBlur(e);
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                {formState.errors?.content_text1 && (
                  <small id="content_text1" className="p-error block pt-1">
                    filed required
                  </small>
                )}
              </div>
            </div>

            {/* Ingredients*/}
            <div className="field col-12">
              <label htmlFor="content_text2">Ingredients : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: false }}
                  control={control}
                  name="content_text2"
                  render={({ field }) => (
                    <JoditEditor
                      ref={field.ref}
                      value={field.value || ""}
                      config={basicJodithConfig}
                      tabIndex={1}
                      onBlur={(e) => {
                        field.onBlur(e);
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />

                {formState.errors?.content_text2 && (
                  <small id="content_text2" className="p-error block pt-1">
                    filed required
                  </small>
                )}
              </div>
            </div>

            {/* FAQ*/}
            <div className="field col-12">
              <label htmlFor="content_text3">FAQ : </label>
              {faqFields.map((item, index) => (
                <div className="mt-2" key={item.id}>
                  <div className="p-fluid formgrid grid ">
                    <div className="formgrid grid col-12">
                      <div className="field col-10">
                        <label htmlFor="user">Title :</label>
                        <InputText placeholder="Input page name" {...register(`content_section3[${index}].title`)} id="name" type="text" />
                      </div>
                      <div className="field col-2">
                        <label className="block"></label> <br />
                        <Button style={{ width: "100%" }} icon="pi pi-times block w-full" onClick={() => faqFieldsRemove(index)} className=" p-button-danger mr-4" />
                      </div>
                    </div>
                    <div className="field col-12">
                      <label htmlFor="user">Body :</label>
                      <Controller
                        rules={{ required: false }}
                        control={control}
                        name={`content_section3[${index}].body`}
                        render={({ field }) => (
                          <JoditEditor
                            ref={field.ref}
                            value={field.value || ""}
                            config={basicJodithConfig}
                            tabIndex={1}
                            onBlur={(e) => {
                              field.onBlur(e);
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div style={{ padding: 0 }} className=" col-12 lg:col-2">
                <Button type="button" style={{ width: "100%" }} onClick={() => faqFieldsAppend()} label="Add FAQ Field" />
              </div>
            </div>
            {/* <div className="field col-12">
              <label htmlFor="content_text3">FAQ : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: false }}
                  control={control}
                  name="content_text3"
                  render={({ field }) => (
                    <JoditEditor
                      ref={field.ref}
                      value={field.value || ""}
                      config={basicJodithConfig}
                      tabIndex={1}
                      onBlur={(e) => {
                        field.onBlur(e);
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />

                {formState.errors?.content_text3 && (
                  <small id="content_text3" className="p-error block pt-1">
                    filed required
                  </small>
                )}
              </div>
            </div> */}
          </>
        ) : null}

        {/* images */}
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images Max 1MB / Image: </label>
          <ReactImageUploading style={{ width: "100%" }} maxNumber={99} maxFileSize={1000000} onError={hanldeErrorImageUpload} multiple value={images} onChange={onChangeImage} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>

        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Product fact icons Max 1MB: </label>
          <ReactImageUploading style={{ width: "100%" }} maxNumber={99} multiple value={imageCustom} onError={hanldeErrorImageUpload} onChange={onChangeImageCustom} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="field col-12">
          <label htmlFor="price">VIP Launching Date (Optional) : </label>
          <Controller control={control} name="launching_date" render={({ field }) => <Calendar showButtonBar placeholder="Select periode range" inputClassName="w-full" className="block w-full" value={field.value} onChange={(e) => field.onChange(e.value)} selectionMode="range" />} />
          <p style={{ color: "red" }}>Only for VIP members, for non VIP members it will appear after a period of time, don't fill it in if you want the product to be displayed immediately</p>
        </div>
        {/* variants */}
        {!is_freebie_watch ? (
          <div className="col-12 field flex align-items-center">
            <Checkbox
              onChange={(e) => {
                setIsVariant(e.checked);
              }}
              checked={isVariant}
            ></Checkbox>
            <p className="font-bold uppercase ml-2">Use Product Variants</p>
          </div>
        ) : null}

        {/* variants details */}
        {isVariant ? (
          <>
            {/* variant input */}
            {variantOneFields.map((item, idx) => (
              <div key={item.id} className="field flex col-12 p-0">
                <div className="field col-4">
                  <label htmlFor={`variant[${idx}].type`}>Variant Type ({idx + 1}) : </label>
                  <div className="w-full">
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue={""}
                      name={`variant[${idx}].type`}
                      render={({ field }) => (
                        <Dropdown
                          ref={field.ref}
                          optionLabel="label"
                          optionValue="value"
                          value={field.value}
                          onBlur={field.onBlur}
                          options={idx > 0 && variants_watch[0].type ? variantTypeOptions.filter((v) => v.value !== variants_watch[0].type) : variantTypeOptions}
                          className="w-full"
                          onChange={(e) => {
                            setVariantWatch((prev) => [...prev, e.value]);
                            field.onChange(e);
                          }}
                          placeholder="Select Variant"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field col-6">
                  <label htmlFor={`variant[${idx}].value`}>Value : </label>

                  <div className="w-full">
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue={[]}
                      name={`variant[${idx}].value`}
                      render={({ field }) => (
                        <AutoComplete
                          multiple
                          inputId={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          inputRef={field.ref}
                          suggestions={variantWatch[idx] === "Color" ? colorOptions : variantWatch[idx] === "Size" ? sizeOptions : null}
                          completeMethod={variantWatch[idx] === "Color" ? searchColorOption : variantWatch[idx] === "Size" ? searchSizeOption : null}
                          inputClassName="w-full"
                          placeholder="Input value"
                          className={classNames("w-full")}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field col-2 mt-auto">
                  <Button type="button" className="p-button-danger w-full" icon="pi pi-trash" onClick={() => variantOneRemove(idx)} />
                </div>
              </div>
            ))}

            {variantWatch.length < 2 && (
              <div className="field col-12">
                <Button onClick={() => variantOneAppend("")} type="button" label="Add Variant" />
              </div>
            )}

            {/* variant table */}
            {variantCombination.length ? (
              <>
                <div className="col-12">
                  <p className="font-bold uppercase">Product Table</p>
                </div>
                <div style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }} className="field flex col-12 p-0 py-3 mb-5">
                  <div className="col-3">Variant</div>
                  <div className="col-3">Price</div>
                  <div className="col-3">Qty</div>
                  <div className="col-3">Image Index</div>
                </div>
                {variantCombination.map((item, idx) => (
                  <div key={idx * 120} className="field flex col-12 p-0">
                    <div className="field col-3">{item.variant.join(" - ") || "-"}</div>
                    <div className="field col-3">
                      <div className="w-full">
                        <InputNumber
                          className="w-full"
                          showButtons
                          placeholder="Input variant value depend on variant type"
                          value={item.price}
                          onChange={(e) => {
                            item.price = e.value;
                            setVariantCombination([...variantCombination]);
                          }}
                          id="name"
                        />
                      </div>
                    </div>
                    <div className="field col-3">
                      <div className="w-full">
                        <InputNumber
                          className="w-full"
                          showButtons
                          placeholder="Input variant value depend on variant type"
                          value={item.qty}
                          onChange={(e) => {
                            item.qty = e.value;
                            setVariantCombination([...variantCombination]);
                          }}
                          id="name"
                        />
                      </div>
                    </div>
                    <div className="field col-3">
                      <div className="w-full">
                        <InputNumber
                          showButtons
                          placeholder="Input variant value depend on variant type"
                          value={item.image_index}
                          onChange={(e) => {
                            item.image_index = e.value;
                            setVariantCombination([...variantCombination]);
                          }}
                          id="name"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </>
        ) : null}

        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/product">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(ProductCreate, comparisonFn);
