// hooks
import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";
// import { DevTool } from "@hookform/devtools";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// config
import { InputNumber } from "primereact/inputnumber";

// api related
import { InputSwitch } from "primereact/inputswitch";
import Api from "../../api/Api";

const HeaderSettingCreate = () => {
  const navigate = useNavigate();

  // hooks
  const { control, register, handleSubmit } = useForm();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "childs",
  });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/header", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/header");
        toast.success("Header created!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    crateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Header
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card col-12 mx-auto">
        <Toolbar className="mb-4" left={leftToolbar} />
        <div className="p-fluid formgrid grid ">
          <div className="field col-12 ">
            <label htmlFor="user">Route Name :</label>
            <InputText placeholder="Input page name" {...register("name")} id="name" type="text" />
          </div>
          <div className="field col-12 ">
            <label htmlFor="user">Route Path :</label>
            <InputText placeholder="Example /stories" {...register("route")} id="route" type="text" />
          </div>
          <div className="field col-12 ">
            <label htmlFor="order">Order :</label>
            <Controller defaultValue="" control={control} name={"order"} render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="field col-12 ">
            <label htmlFor="email">Active Status</label>
            <Controller
              control={control}
              defaultValue={false}
              name="active_status"
              render={({ field }) => (
                <InputSwitch
                  className="block"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  checked={field.value}
                />
              )}
            />
          </div>
        </div>
        {fields.map((item, index) => (
          <div key={item.id}>
            <div className="p-fluid formgrid grid ">
              <div className="field col-12 lg:col-4">
                <label htmlFor="user">Route Name :</label>
                <InputText placeholder="Input page name" {...register(`childs.${index}.name`)} id="name" type="text" />
              </div>
              <div className="field col-12 lg:col-4">
                <label htmlFor="user">Route Path :</label>
                <InputText placeholder="Example /stories" {...register(`childs.${index}.route`)} id="route" type="text" />
              </div>
              <div className="field col-12 lg:col-3">
                <label htmlFor="order">Order :</label>
                <Controller defaultValue="" control={control} name={`childs.${index}.order`} render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
              </div>
              <div className="field col-12 lg:col-1">
                <label className="block"></label> <br />
                <Button style={{ width: "100%" }} icon="pi pi-times block w-full" onClick={() => remove(index)} className=" p-button-danger mr-4" />
              </div>
            </div>
          </div>
        ))}
        <section>
          <Button type="button" label="Add Field" onClick={() => append({})} className=" p-button-primary mr-4" />
        </section>
        <div className="flex justify-content-center mt-4">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/header">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(HeaderSettingCreate, comparisonFn);
