// hooks
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";

const HomeTestimony = ({ permissions }) => {
  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [uploadThumb, setUploadThum] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // api calling
  const {
    isLoading,
    data: homeBannerData,
    refetch,
  } = useQuery(
    "home-testimony",
    async () => {
      try {
        const res = await Api().get("/home-testimony");

        if (res.data.status !== 200) {
          throw new Error(res.data.message);
        }

        return res.data.data;
      } catch (error) {
        toast.error(error.message);
      }
    },
    { initialData: [] }
  );

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("home-testimony", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        refetch();
        reset({});
        setSelectedFile(null);
        setShowCreateModal(false);
        toast.success("Home Testimony Created!", { duration: 4000 });
      } catch (error) {
        refetch();
        setShowCreateModal(false);
        toast.error(error.message || "something went wrong");
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/home-testimony/edit", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        refetch();
        updateForm.reset({});
        setSelectedFile(null);
        setShowEditModal(false);
        toast.success("Home Testimony Updated!", { duration: 4000 });
      } catch (error) {
        refetch();
        setShowEditModal(false);
        toast.error(error.message || "something went wrong");
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("home-testimony", { data }), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Home Testimony Deleted!", { duration: 4000 });
      } catch (error) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // react hook
  const { register, reset, handleSubmit, formState, getValues } = useForm();
  const updateForm = useForm();

  // functions
  const create = (data) => {
    // image required
    const formData = new FormData();

    if (selectedFile) {
      formData.append("files", selectedFile);
    }
    formData.append("description", data.description);
    formData.append("title", data.title);
    formData.append("link", data.link);
    crateMutate(formData);
  };

  const update = (data) => {
    const formData = new FormData();

    if (data.images?.length && data.images[0]) {
      formData.append("images", JSON.stringify(data.images[0]));
    } else {
      formData.append("images", JSON.stringify(""));
    }

    if (selectedFile) {
      formData.append("files", selectedFile);
      delete data.images;
    }
    formData.append("description", data.description);
    formData.append("title", data.title);
    formData.append("link", data.link);
    formData.append("home_testimony_id", data._id);
    updateMutate(formData);
  };

  const handleRemoveImage = (type) => {
    let currentValues = type === "update" ? updateForm.getValues() : getValues();
    let getInputFiles = document.querySelector("#upload-file");

    // to remove file in input
    if (getInputFiles.value) {
      getInputFiles.value = null;
    }

    if (selectedFile) {
      setSelectedFile(null);
      setUploadThum(null);
    }

    if (currentValues.images?.length && currentValues.images[0]) {
      currentValues.images = [];
      setUploadThum(null);
    }

    if (type === "update") {
      updateForm.reset(currentValues);
    } else {
      reset(currentValues);
    }
  };

  const showNewModal = () => {
    setUploadThum(null);
    reset({});
    setShowCreateModal(true);
  };

  const handleUpload = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploadThum(createBlob);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      home_testimony_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.home_testimony_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      home_testimony_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = (data) => {
    const img = data?.images[0];
    setUploadThum(img?.url || null);
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}
          {permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}
        </div>
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    let firstImage = rowData?.images[0];

    return (
      <>
        <Image style={{ backgroundColor: "black" }} preview src={firstImage?.url} alt={rowData?.image} className="shadow-2" width="100" />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button disabled={!permissions.update} icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />
        <Button disabled={!permissions.delete} icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Home Testimony</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={homeBannerData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Image found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="a" header="Image" body={imageBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="title" header="Title" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="created_at" body={(data) => NewTimeFormatter(data.created_at)} header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header="Create Home Testimony" modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="name">Title</label>
            <InputText placeholder="Input title" {...register("title", { required: true })} id="name" type="text" className={formState.errors.title && "p-invalid"} />
            {formState.errors.title && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Description</label>
            <InputText placeholder="Input description" {...register("description", { required: false })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="link">Link</label>
            <InputText defaultValue={""} placeholder="Input link" {...register("link", { required: false })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input id="upload-file" onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image style={{ backgroundColor: "black" }} preview width="85px" src={uploadThumb} alt="thumbnails" />
              <div className="w-full">
                <Button type="button" onClick={() => handleRemoveImage("create")} className="p-button p-button-danger mt-2" style={{ width: "fit-content" }}>
                  Remove Image
                </Button>
              </div>
            </div>
          )}

          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="Edit Home Testimony" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="title">Title</label>
            <InputText placeholder="Input title" {...updateForm.register("title", { required: true })} id="name" type="text" className={updateForm.formState.errors.title && "p-invalid"} />
            {updateForm.formState.errors.title && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Description</label>
            <InputText placeholder="Input description" {...updateForm.register("description", { required: false })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="link">Link</label>
            <InputText defaultValue={""} placeholder="Input link" {...updateForm.register("link", { required: false })} id="name" type="text" />
          </div>

          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input id="upload-file" onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div>
              <Image style={{ backgroundColor: "black" }} preview width="85px" src={uploadThumb} alt="thumbnails" />
              <div className="w-full">
                <Button type="button" onClick={() => handleRemoveImage("update")} className="p-button p-button-danger mt-2" style={{ width: "fit-content" }}>
                  Remove Image
                </Button>
              </div>
            </div>
          )}

          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Item?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(HomeTestimony, comparisonFn);
