import Api from "./Api";

export default {
  Get() {
    return Api().get("/quiz");
  },
  GetResult(data) {
    return Api().post("/quiz/result", data);
  },
  SaveResult(data) {
    return Api().post("/quiz/save", data);
  },
  GetUserResults(data) {
    return Api().post("/quiz/result-user", data);
  },
};
