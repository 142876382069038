// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";
// import { DevTool } from "@hookform/devtools";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import moment from "moment/moment";

const transaction_setting_type = [
  { type: 1, label: "Free Gift Product" },
  { type: 2, label: "Additional" },
];

const TransactionSettingCreate = () => {
  const navigate = useNavigate();

  // hooks
  const { control, handleSubmit, formState, watch } = useForm();
  const discount_type_watch = watch("type");
  const can_multiple_watch = watch("can_multiple");
  const product_watch = watch("products");

  // query
  const { data: productOption } = useQuery(["freebies product"], () => getProduct());
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/transaction-setting", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/transaction-setting");
        toast.success("Transaction Setting created!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getProduct = async () => {
    try {
      const res = await Api().get("/product?freebies=true");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const onSubmit = (data) => {
    data.start_date = moment(data.periode[0]).format("YYYY-MM-DD");
    data.end_date = data.periode[1] ? moment(data.periode[1]).format("YYYY-MM-DD") : moment(data.periode[0]).format("YYYY-MM-DD");

    if (data.periode[0]) {
      data.start_time = moment(data.periode[0]).format("HH:mm:ss");
    } else {
      data.start_time = "00:00:00";
    }

    if (data.periode[1]) {
      data.end_time = moment(data.periode[1]).format("HH:mm:ss");
    } else {
      data.end_time = "23:59:59";
    }

    delete data.periode;
    crateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Transaction Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0].url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>

        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="field col-12">
          <label htmlFor="type">Transaction Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="type"
              render={({ field }) => <Dropdown className="w-full" emptyFilterMessage="no data found" value={field.value} options={transaction_setting_type} onChange={(e) => field.onChange(e)} optionLabel="label" optionValue="type" placeholder="Select type" display="chip" />}
            />
            {formState.errors?.type && (
              <small id="type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        {discount_type_watch === 2 ? (
          <div className="field col-12">
            <label htmlFor="content">Lable : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                name="content"
                defaultValue={""}
                render={({ field }) => (
                  <InputTextarea
                    value={field.value}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    onChange={(e) => field.onChange(e)}
                    className={classNames(
                      {
                        "p-invalid": formState.errors?.content,
                      },
                      "w-full"
                    )}
                    placeholder="Write something..."
                    autoResize
                    rows={1}
                  />
                )}
              />
              {formState.errors?.content && (
                <small id="content" className="p-error block pt-1">
                  filed required
                </small>
              )}
            </div>
          </div>
        ) : null}

        {discount_type_watch === 2 ? (
          <div className="field col-12">
            <label htmlFor="using_note">Use Note : </label>
            <div className="w-full">
              <Controller control={control} defaultValue={false} name="using_note" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
            </div>
          </div>
        ) : null}

        {discount_type_watch === 1 ? (
          <div className="field col-12">
            <label htmlFor="products">Gift Products : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="products"
                render={({ field }) => (
                  <MultiSelect
                    itemTemplate={productSelectTemplate}
                    className="w-full"
                    emptyFilterMessage="no data found"
                    disabled={!productOption?.length}
                    value={field.value}
                    options={productOption}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="_id"
                    placeholder="Select requirement products"
                    display="chip"
                  />
                )}
              />
              {formState.errors?.products && (
                <small id="products" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        ) : null}

        {can_multiple_watch ? (
          <div className="field col-12">
            <label htmlFor="limit_item">Limit Item : </label>
            <Controller
              defaultValue={1}
              control={control}
              name="limit_item"
              render={({ field }) => (
                <InputNumber
                  min={1}
                  max={product_watch?.length}
                  disabled={!product_watch?.length}
                  className={classNames({ "p-invalid": formState.errors?.limit_item }, "w-full")}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  value={field.value}
                  onValueChange={(e) => field.onChange(e)}
                  showButtons
                  mode="decimal"
                  placeholder="0"
                />
              )}
            />
            {formState.errors?.limit_item && (
              <small id="limit_item" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        ) : null}

        {discount_type_watch === 1 ? (
          <div className="field col-12">
            <label htmlFor="can_multiple">Multiple Product (user can multiple select product gift) : </label>
            <div className="w-full">
              <Controller control={control} defaultValue={false} name="can_multiple" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
            </div>
          </div>
        ) : null}

        {discount_type_watch === 2 ? (
          <div className="field col-12">
            <label htmlFor="addition_price">Additional Price (IDR) : </label>
            <Controller
              defaultValue={""}
              control={control}
              name="addition_price"
              render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.addition_price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
            />
            {formState.errors?.addition_price && (
              <small id="addition_price" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        ) : null}
        <div className="field col-12">
          <label htmlFor="min_transaction_price">Min Transaction Price (IDR) : </label>
          <Controller
            defaultValue={""}
            control={control}
            name="min_transaction_price"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.min_transaction_price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.min_transaction_price && (
            <small id="min_transaction_price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="min_transaction_item">Min Item : </label>
          <Controller
            defaultValue={1}
            control={control}
            name="min_transaction_item"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.min_transaction_item }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.min_transaction_item && (
            <small id="min_transaction_item" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>

        <div className="field col-12 lg:col-6">
          <label htmlFor="periode">Periode : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="periode"
              render={({ field }) => (
                <Calendar
                  hourFormat="24"
                  showTime
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  minDate={new Date()}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.description,
                    },
                    "w-full"
                  )}
                  placeholder="Select periode range"
                  selectionMode="range"
                  readOnlyInput
                />
              )}
            />
            {formState.errors?.periode && (
              <small id="periode" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="field col-12">
          <label htmlFor="addition_price">Order Place : </label>
          <Controller
            defaultValue={1}
            control={control}
            name="order"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.order }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
        </div>

        <div className="field col-12">
          <label htmlFor="active_status">Active Status : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/transaction-setting">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(TransactionSettingCreate, comparisonFn);
