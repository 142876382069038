// hooks
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
// import { DevTool } from "@hookform/devtools";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { Image } from "primereact/image";
import { TabView, TabPanel } from "primereact/tabview";
import PDF from "../../components/PDF/PDF";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import formatRupiah from "../../utils/formatRupiah";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";

const RequestPickup = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 25;
  const buttonRequestMultipleEl = useRef(null);
  const [buttonRequestMultipleVisible, setButtonRequestMultipleVisible] = useState(false);

  // state
  const [selectedPrintedStatus, setSelectedPrintedStatus] = useState(false);
  const [pageNew, setPageNew] = React.useState(1);
  const [pageOnProgress, setPageOnProgress] = React.useState(1);
  const [pageDelivered, setPageDelivered] = React.useState(1);
  const [loadingPrintAllLabel, setLoadingPrintAllLabel] = useState(false);

  const [pageDatTableNew, setPageDataTableNew] = useState({ first: 0, rows: 10, page: 1 });
  const [pageDatTableOnProgress, setPageDataTableOnProgress] = useState({ first: 0, rows: 10, page: 1 });
  const [pageDatTableDelivered, setPageDataTableDelivered] = useState({ first: 0, rows: 10, page: 1 });

  const [pageInfoNew, setPageInfoNew] = useState({});
  const [pageInfoOnProgress, setPageInfoOnProgress] = useState({});
  const [pageInfoDelivered, setPageInfoDelivered] = useState({});

  const [expandedRows, setExpandedRows] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [selectItemsLabel, setSelectItemsLabel] = useState([]);

  // api calling
  const {
    data: requestPickupDataNew,
    isLoading: loadingRequestPickupDataNew,
    refetch: refetchRequestPickupDataNew,
  } = useQuery(
    {
      queryKey: ["pickup-request", "new", pageNew],
      queryFn: () => getTransactionsNew(pageNew),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  const {
    data: requestPickupDataOnProgress,
    isLoading: loadingRequestPickupDataOnProgress,
    refetch: refetchRequestPickupDataOnProgress,
  } = useQuery(
    {
      queryKey: ["pickup-request", "on-progress", pageOnProgress, selectedPrintedStatus],
      queryFn: () => getTransactionsOnProgress(pageOnProgress),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  const {
    data: requestPickupDataDelivered,
    isLoading: loadingRequestPickupDataDelivered,
    refetch: refetchRequestPickupDataDelivered,
  } = useQuery(
    {
      queryKey: ["pickup-request", "delivered", pageDelivered],
      queryFn: () => getTransactionsDelivered(pageDelivered),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: loadingRequestPickup, mutate: requestPickupMutate } = useMutation(async (data) => await Api().post("/courier/pickup-request", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.success("Request Pickup Success", { duration: 4000 });
      } catch (error) {
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.error(error.message || "something went wrong");
      }
    },
  });
  const { isLoading: loadingRequestPickupMultiple, mutate: requestPickupMultipleMutate } = useMutation(async (data) => await Api().post("/courier/multiple-pickup-request", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.success("Request Pickup Success", { duration: 4000 });
      } catch (error) {
        setSelectItems([]);
        refetchRequestPickupDataNew();
        refetchRequestPickupDataOnProgress();
        refetchRequestPickupDataDelivered();
        toast.error(error.message || "something went wrong");
      }
    },
  });
  const { isLoading: loadingMarkPrintLabel, mutate: markAsPrintedLabel } = useMutation(async (data) => await Api().post("/transaction/print", data), {
    onSettled: async (response, error) => {
      try {
        if (error) {
          throw new Error();
        }

        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        refetchRequestPickupDataOnProgress();
        setSelectItemsLabel([]);
      } catch (error) {
        toast.error(error.message || "Failed while marking printed label");
      }
    },
  });

  // functions
  const getTransactionsNew = async () => {
    try {
      const res = await Api().get(`/transaction/pickup-request?limit=${data_limit}&page=${pageNew}&status_shipping=0`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfoNew(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getPrintLabelFilename = () => {
    let date = moment().format("DD-MM-YYYY");
    let filename = "TULUS-LABEL-" + date;

    return filename;
  };

  const handlePrintAllLabel = async () => {
    try {
      setLoadingPrintAllLabel(true);

      const res = await Api().get(`/transaction/pickup-request?status_shipping=2`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      if (res?.data?.data?.length) {
        getPdfBlob(res.data.data, getPrintLabelFilename());
      }

      setLoadingPrintAllLabel(false);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      setLoadingPrintAllLabel(false);
      return error;
    }
  };

  const getTransactionsOnProgress = async () => {
    try {
      const res = await Api().get(`/transaction/pickup-request?limit=${data_limit}&page=${pageOnProgress}&status_shipping=2&printed_resi=${selectedPrintedStatus}`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfoOnProgress(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getTransactionsDelivered = async () => {
    try {
      const res = await Api().get(`/transaction/pickup-request?limit=${data_limit}&page=${pageDelivered}&status_shipping=3`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfoDelivered(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getPdfBlob = async (data, filename) => {
    let temp = [];

    for (let i = 0; i < data.length; i++) {
      let total_weight = 0;

      for (let j = 0; j < data[i].detail.length; j++) {
        if (data[i].detail[j]?.product) {
          total_weight = total_weight + (data[i].detail[j]?.product?.weight || 0);
        } else {
          toast.error("there is an error in product data.");
        }
      }
      temp.push({ ...data[i], total_weight: total_weight / 1000 });
    }

    // create new attribute for freebies_product
    for (let i = 0; i < temp.length; i++) {
      let temp_product = [];
      const get_freebies_products = temp[i].free_gifts.filter((free) => free.products.length);
      const get_product_notes = temp[i].free_gifts.filter((free) => free.note?.length);

      for (let j = 0; j < get_freebies_products.length; j++) {
        temp_product = [...temp_product, ...get_freebies_products[j].products];
      }

      temp[i].user_notes = get_product_notes;
      temp[i].freebies_product = temp_product;
    }

    let file_name = filename || `TULUS-${temp[0].resi || temp[0].transaction_id}`;
    let blobPdf = await pdf(PDF({ pdfData: temp })).toBlob();

    const transaction_ids = temp.map((transaction) => transaction._id);
    markAsPrintedLabel({
      transaction_ids: transaction_ids,
    });

    var fileURL = URL.createObjectURL(blobPdf);
    var a = document.createElement("a");
    a.href = fileURL;
    a.target = "_blank";
    a.download = file_name;
    a.click();
  };

  const pageHandlerNew = (data) => {
    setPageDataTableNew(data);
    setPageNew(data.page + 1);
  };

  const pageHandlerOnProgress = (data) => {
    setPageDataTableOnProgress(data);
    setPageOnProgress(data.page + 1);
  };

  const pageHandlerDelivered = (data) => {
    setPageDataTableDelivered(data);
    setPageDelivered(data.page + 1);
  };

  // child components
  const rowExpansionTemplate = (rowData) => {
    let freebies_data = rowData.free_gifts.filter((freebies) => freebies.gift_id.type === 1);
    let additional_data = rowData.free_gifts.filter((additional) => additional.gift_id.type === 2);

    return (
      <>
        <div className="p-3">
          <p>
            <span className="font-bold">PRODUCTS LIST</span>
          </p>
          <DataTable value={rowData.detail}>
            <Column
              body={(data) => (
                <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                  <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.product?.images[0]?.url} alt="image" />
                </div>
              )}
              headerStyle={{ width: "150px", minWidth: "80px" }}
            ></Column>
            <Column field="product.name" header="Product Name"></Column>
            <Column field="variant_index" header="Variant" body={(d) => d.product?.variant_detail[d.variant_index]?.variant.join(" - ") || "-"}></Column>
            <Column field="qty" header="Qty"></Column>
            <Column field="total_price" header="Price" body={(d) => formatRupiah(d.total_price)}></Column>
            <Column headerStyle={{ width: "4rem" }}></Column>
          </DataTable>
        </div>
        <div className="p-3">
          {freebies_data.length ? (
            <div className="">
              <p>
                <span className="font-bold">FREEBIES PRODUCTS</span>
              </p>
              {freebies_data.map((freebies) => (
                <DataTable key={freebies._id} value={freebies.products}>
                  <Column
                    body={(data) => {
                      console.log(data);
                      return (
                        <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                          <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.images[0]?.url} alt="image" />
                        </div>
                      );
                    }}
                    headerStyle={{ width: "150px", minWidth: "80px" }}
                  ></Column>
                  <Column field="name" header="Product Name"></Column>
                  <Column headerStyle={{ width: "4rem" }}></Column>
                </DataTable>
              ))}
            </div>
          ) : null}

          {additional_data.length ? (
            <div className="mt-4">
              <span className="font-bold uppercase ">SELECTED Additional</span>
              <div>
                {additional_data.map((additional) => (
                  <div className="mt-3 card">
                    <div className="font-medium uppercase"> {additional.gift_id.title}</div>
                    <div className=" flex mt-4">
                      <p style={{ width: "120px" }}>TEXT CONTENT</p>
                      <p>
                        : <span className="ml-4">{additional.gift_id.content}</span>
                      </p>
                    </div>
                    {additional.note ? (
                      <div className=" flex ">
                        <p style={{ width: "120px" }}>BUYER NOTE</p>
                        <p>
                          : <span className="ml-4">{additional.note}</span>
                        </p>
                      </div>
                    ) : null}
                    <div className=" flex ">
                      <p style={{ width: "120px" }}>PRICE</p>
                      <p>
                        : <span className="ml-4">{formatRupiah(additional.additional_price)}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };

  const actionBodyTemplate = (rowData) => {
    const accept = () => {
      requestPickupMutate({ transaction_id: rowData._id });
    };

    return (
      <div className="actions">
        <ConfirmPopup />
        <div className="flex justify-content-center">
          <Button
            loading={loadingRequestPickup}
            onClick={(e) => {
              confirmPopup({
                target: e.currentTarget,
                message: "Request Pickup for " + rowData.transaction_id,
                icon: "pi pi-exclamation-triangle",
                accept,
              });
            }}
            icon="pi pi-car"
            label="Request"
          />
        </div>
      </div>
    );
  };

  const requestAllNewTransaction = () => {
    let temp_ids = [];

    for (let i = 0; i < selectItems.length; i++) {
      temp_ids.push(selectItems[i]._id);
    }

    requestPickupMultipleMutate({ transaction_ids: temp_ids });
  };

  return (
    <>
      <TabView className="card custom-tabs">
        {/* new transaction */}
        <TabPanel header="New Transaction">
          <div className="grid crud-demo ">
            <div className="w-full">
              <div className="col-12">
                <ConfirmPopup target={buttonRequestMultipleEl.current} visible={buttonRequestMultipleVisible} onHide={() => setButtonRequestMultipleVisible(false)} message="Are you sure you want to proceed?" icon="pi pi-exclamation-triangle" accept={requestAllNewTransaction} />
                <Button ref={buttonRequestMultipleEl} loading={loadingRequestPickupMultiple} disabled={!selectItems.length} onClick={() => setButtonRequestMultipleVisible(true)} label={` Request Pickup (${selectItems.length})`} />
              </div>
              <div className=" col-12 mx-auto">
                <DataTable
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  loading={loadingRequestPickupDataNew}
                  value={requestPickupDataNew}
                  selection={selectItems}
                  onSelectionChange={(e) => setSelectItems(e.value)}
                  dataKey="_id"
                  paginator
                  rows={data_limit}
                  lazy
                  onPage={pageHandlerNew}
                  first={pageDatTableNew.first}
                  totalRecords={pageInfoNew.total_data}
                  rowExpansionTemplate={rowExpansionTemplate}
                  className="datatable-responsive"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  emptyMessage="No Data Found."
                  responsiveLayout="scroll"
                >
                  <Column selectionMode="multiple" headerStyle={{ width: "1rem" }}></Column>
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
                  <Column field="user.email" header="User email" body={(data) => data?.user?.email || data.guest_email} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
                  <Column field="user.email" header="Reciever" body={(data) => data?.shipping_address?.user_name || data?.guest_name} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
                  <Column field="price" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ width: "10%", minWidth: "13rem" }}></Column>
                  <Column field="total_payment" header="Total Payment" body={(d) => formatRupiah(d.total_payment)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
                  <Column field="shipping_price" header="Shipping" body={(d) => formatRupiah(d.shipping_price)} headerStyle={{ width: "10%", minWidth: "8rem" }}></Column>
                  <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ minWidth: "4rem" }}></Column>
                  {permissions.update || permissions.delete ? <Column header="ACTIONS" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
                </DataTable>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* on going */}
        <TabPanel header="On Going">
          <div className="grid crud-demo ">
            <div className="w-full">
              <div className="col-12 flex justify-content-between">
                <div className="col-6 p-0">
                  <Button
                    onClick={() => {
                      const filename = getPrintLabelFilename();
                      selectItemsLabel.length ? getPdfBlob(selectItemsLabel, filename + "-" + selectItemsLabel.length) : handlePrintAllLabel();
                    }}
                    loading={loadingPrintAllLabel || loadingMarkPrintLabel}
                    disabled={!requestPickupDataOnProgress?.length}
                    label={selectItemsLabel.length ? `Print Label (${selectItemsLabel?.length})` : "Print All Label"}
                  />
                  {selectItemsLabel.length ? (
                    <Button
                      className="p-button-danger ml-2"
                      onClick={() => {
                        setSelectItemsLabel([]);
                      }}
                      label="Clear"
                    />
                  ) : null}
                </div>
                <div className="">
                  <Dropdown
                    style={{ width: "100%", maxWidth: "200px" }}
                    value={selectedPrintedStatus}
                    options={[
                      { label: "Print", value: false },
                      { label: "Printed", value: true },
                    ]}
                    onChange={(e) => {
                      setSelectedPrintedStatus(e.value);
                      setPageOnProgress(1);
                      setPageDataTableOnProgress({ first: 0, rows: 10, page: 1 });
                    }}
                    placeholder="Product Filter"
                  />
                </div>
              </div>
              <div className=" col-12 mx-auto">
                <DataTable
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  loading={loadingRequestPickupDataOnProgress}
                  value={requestPickupDataOnProgress}
                  dataKey="_id"
                  paginator
                  selection={selectItemsLabel}
                  onSelectionChange={(e) => setSelectItemsLabel(e.value)}
                  rows={data_limit}
                  lazy
                  onPage={pageHandlerOnProgress}
                  first={pageDatTableOnProgress.first}
                  totalRecords={pageInfoOnProgress.total_data}
                  rowExpansionTemplate={rowExpansionTemplate}
                  className="datatable-responsive"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  emptyMessage="No Data Found."
                  responsiveLayout="scroll"
                >
                  <Column selectionMode="multiple" headerStyle={{ width: "1rem" }}></Column>
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column
                    field="resi"
                    header="Resi"
                    body={(d) => (
                      <a className=" font-bold" target="_blank" rel="noopener noreferrer" href={"https://www.sicepat.com/checkAwb"}>
                        {d.resi}
                      </a>
                    )}
                    headerStyle={{ width: "auto", minWidth: "2rem" }}
                  ></Column>
                  <Column field="user.name" header="User" body={(data) => data.user?.name || data?.guest_name} headerStyle={{ minWidth: "10rem" }}></Column>
                  <Column field="user.email" header="Reciever" body={(data) => data?.shipping_address?.user_name || data?.guest_name} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
                  <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
                  <Column field="price" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ minWidth: "13rem" }}></Column>
                  <Column field="total_amount" header="Total Price" body={(d) => formatRupiah(d.total_amount)} headerStyle={{ minWidth: "10rem" }}></Column>
                  <Column field="shipping_price" header="Shipping" body={(d) => formatRupiah(d.shipping_price)} headerStyle={{ minWidth: "8rem" }}></Column>
                  <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ width: "8%", minWidth: "3rem" }}></Column>
                  <Column field="status" className="capitalize" header="Action" body={(d) => <Button onClick={() => getPdfBlob([d])} icon="fas fa fa-solid fa-tags" label={d.printed_resi ? "Printed" : "Print"} />} headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* delivered */}
        <TabPanel header="Delivered">
          <div className="grid crud-demo ">
            <div className="w-full">
              <div className=" col-12 mx-auto">
                <DataTable
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  loading={loadingRequestPickupDataDelivered}
                  value={requestPickupDataDelivered}
                  selection={selectItems}
                  onSelectionChange={(e) => setSelectItems(e.value)}
                  dataKey="_id"
                  paginator
                  rows={data_limit}
                  lazy
                  onPage={pageHandlerDelivered}
                  first={pageDatTableDelivered.first}
                  totalRecords={pageInfoDelivered.total_data}
                  rowExpansionTemplate={rowExpansionTemplate}
                  className="datatable-responsive"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  emptyMessage="No Data Found."
                  responsiveLayout="scroll"
                >
                  <Column expander={allowExpansion} style={{ width: "5rem" }} />
                  <Column
                    field="resi"
                    header="Resi"
                    body={(d) => (
                      <a className=" font-bold" target="_blank" rel="noopener noreferrer" href={"https://www.sicepat.com/checkAwb"}>
                        {d.resi}
                      </a>
                    )}
                    headerStyle={{ width: "auto", minWidth: "2rem" }}
                  ></Column>
                  <Column field="user.name" header="User" body={(data) => data.user?.name || data?.guest_name} headerStyle={{ minWidth: "10rem" }}></Column>
                  <Column field="user.email" header="Reciever" body={(data) => data?.shipping_address?.user_name || data?.guest_name} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
                  <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
                  <Column field="price" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ minWidth: "13rem" }}></Column>
                  <Column field="total_amount" header="Total Price" body={(d) => formatRupiah(d.total_amount)} headerStyle={{ minWidth: "10rem" }}></Column>
                  <Column field="shipping_price" header="Shipping" body={(d) => formatRupiah(d.shipping_price)} headerStyle={{ minWidth: "8rem" }}></Column>
                  <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ width: "8%", minWidth: "3rem" }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(RequestPickup, comparisonFn);
