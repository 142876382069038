import Users from "./pages/Users/Users";
import Role from "./pages/Role/Role";
import Page from "./pages/PageSetting/Page";
import CreatePage from "./pages/PageSetting/CreatePage";
import CreateRole from "./pages/Role/CreateRole";
import UpdateRole from "./pages/Role/UpdateRole";
import AccountSetting from "./pages/v2/AccountSetting";
import UpdateUser from "./pages/Users/UpdateUser";
import Product from "./pages/Product/Product";
import ProductCreate from "./pages/Product/ProductCreate";
import ProductCategory from "./pages/ProductCategory/ProductCategory";
import ProductUpdate from "./pages/Product/ProductUpdate";
import ListOrder from "./pages/ListOrder/ListOrder";
import ListOrderUpdate from "./pages/ListOrder/ListOrderUpdate";
import Discount from "./pages/Discount/Discount";
import DiscountCreate from "./pages/Discount/DiscountCreate";
import DiscountUpdate from "./pages/Discount/DiscountUpdate";
import UserCart from "./pages/UserCart/UserCart";
import HomeBanner from "./pages/HomeBanner/HomeBanner";
import PaymentSetting from "./pages/PaymentSetting/PaymentSetting";
import UpdateHomePage from "./pages/HomePageSetting/UpdateHomePage";
import HeaderSetting from "./pages/Header/HeaderSetting";
import RequestPickup from "./pages/RequestPickup/RequestPickup";
import Courier from "./pages/Courier/Courier";
import MerchentAddress from "./pages/MerchentAddress";
import HeaderSettingCreate from "./pages/Header/HeaderSettingCreate";
import HeaderSettingUpdate from "./pages/Header/HeaderSettingUpdate";
import ProductIngridient from "./pages/ProductIngridient/ProductIngridient";
import JournalCategory from "./pages/JournalCategory/JournalCategory";
import Journal from "./pages/Journal/Journal";
import JournalCreate from "./pages/Journal/JournalCreate";
import JournalUpdate from "./pages/Journal/JournalUpdate";
import HomeTestimony from "./pages/HomeTestimony/HomeTestimony";
import StaticPageUpdate from "./pages/StaticPage/StaticPageUpdate";
import StaticPageCreate from "./pages/StaticPage/StaticPageCreate";
import StaticPage from "./pages/StaticPage/StaticPage";
import QuizQuestions from "./pages/Quiz/QuizQuestions";
import Quiz from "./pages/Quiz/Quiz";
import TransactionSetting from "./pages/TransactionSetting/TransactionSetting";
import TransactionSettingCreate from "./pages/TransactionSetting/TransactionSettingCreate";
import TransactionSettingUpdate from "./pages/TransactionSetting/TransactionSettingUpdate";
import UOM from "./pages/UOM/UOM";
import ProductReviews from "./pages/ProductReviews/ProductReviews";
import EmailSetting from "./pages/EmailSetting/EmailSetting";
import EmailSettingCreate from "./pages/EmailSetting/EmailSettingCreate";
import EmailSettingUpdate from "./pages/EmailSetting/EmailSettingUpdate";
import ActivateMemberPoint from "./pages/v2/ActivateMemberPoint";
import MemberCreate from "./pages/Member/MemberCreate";
import MemberUpdate from "./pages/Member/MemberUpdate";
import Member from "./pages/Member/Member";

const pagesList = [
  // with parent
  {
    route: "/dashboard/email-setting",
    component: EmailSetting,
    childs: [
      {
        route: "/dashboard/email-setting/create",
        component: EmailSettingCreate,
        access: "create",
      },
      {
        route: "/dashboard/email-setting/update/:id",
        component: EmailSettingUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/member",
    component: Member,
    childs: [
      {
        route: "/dashboard/member/create",
        component: MemberCreate,
        access: "create",
      },
      {
        route: "/dashboard/member/update/:id",
        component: MemberUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/header",
    component: HeaderSetting,
    childs: [
      {
        route: "/dashboard/header/create",
        component: HeaderSettingCreate,
        access: "create",
      },
      {
        route: "/dashboard/header/update/:id",
        component: HeaderSettingUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/transaction-setting",
    component: TransactionSetting,
    childs: [
      {
        route: "/dashboard/transaction-setting/create",
        component: TransactionSettingCreate,
        access: "create",
      },
      {
        route: "/dashboard/transaction-setting/update/:id",
        component: TransactionSettingUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/product",
    component: Product,
    childs: [
      {
        route: "/dashboard/product/create",
        component: ProductCreate,
        access: "create",
      },
      {
        route: "/dashboard/product/update/:id",
        component: ProductUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/journal",
    component: Journal,
    childs: [
      {
        route: "/dashboard/journal/create",
        component: JournalCreate,
        access: "create",
      },
      {
        route: "/dashboard/journal/update/:id",
        component: JournalUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/static-page",
    component: StaticPage,
    childs: [
      {
        route: "/dashboard/static-page/create",
        component: StaticPageCreate,
        access: "create",
      },
      {
        route: "/dashboard/static-page/update/:id",
        component: StaticPageUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/list-of-order",
    component: ListOrder,
    childs: [
      {
        route: "/dashboard/list-of-order/update/:id",
        component: ListOrderUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/discount",
    component: Discount,
    childs: [
      {
        route: "/dashboard/discount/create",
        component: DiscountCreate,
        access: "create",
      },
      {
        route: "/dashboard/discount/update/:id",
        component: DiscountUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/role",
    component: Role,
    childs: [
      {
        route: "/dashboard/role/create",
        component: CreateRole,
        access: "create",
      },
      {
        route: "/dashboard/role/update/:id",
        component: UpdateRole,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/pages",
    component: Page,
    childs: [
      {
        route: "/dashboard/pages/create",
        component: CreatePage,
        access: "create",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/users",
    component: Users,
    childs: [
      {
        route: "/dashboard/users/update/:id",
        component: UpdateUser,
        access: "update",
      },
    ],
    hasChild: true,
  },

  // new
  {
    route: "/dashboard/uom",
    component: UOM,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-reviews",
    component: ProductReviews,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/quiz",
    component: Quiz,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/quiz-questions",
    component: QuizQuestions,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/merchant-address",
    component: MerchentAddress,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/home-testimony",
    component: HomeTestimony,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-category",
    component: ProductCategory,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/product-ingredient",
    component: ProductIngridient,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/journal-category",
    component: JournalCategory,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/courier",
    component: Courier,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/pickup-request",
    component: RequestPickup,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/home-page",
    component: UpdateHomePage,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/payment",
    component: PaymentSetting,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/home-banner",
    component: HomeBanner,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/user-cart",
    component: UserCart,
    childs: [],
    hasChild: false,
  },

  // dashboard/details is hidden , i hide in login file
  {
    route: "/dashboard/account",
    component: AccountSetting,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/activate-member-point",
    component: ActivateMemberPoint,
    childs: [],
    hasChild: false,
  },
];

export default pagesList;
