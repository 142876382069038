// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";

// utils
import classNames from "classnames";
import DiscountUserTypeOptions from "../../assets/data/discount_user_type.json";
import DiscountTypeOptions from "../../assets/data/discount_type.json";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";
// import { DevTool } from "@hookform/devtools";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import ReactImageUploading from "react-images-uploading";
import { useState } from "react";
import moment from "moment/moment";

const DiscountCreate = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [isUsePercentage, setIsUsePercentage] = useState(false);

  // hooks
  const { control, handleSubmit, formState, watch } = useForm();
  const discount_type_watch = watch("type");
  const discount_type_limit_watch = watch("limit_type");

  // query
  const { data: productOption } = useQuery("product", () => getProduct());
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });
  const { data: courierData } = useQuery("couriers", () => getCouriers(), { initialData: [] });
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("discount", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/discount");
        toast.success("Discount created!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const onChangeImage = (imageList) => {
    setImages(imageList);
  };

  const getProduct = async () => {
    try {
      const res = await Api().get("/product");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getProductCategory = async () => {
    try {
      const res = await Api().get("category");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const getCouriers = async () => {
    try {
      const res = await Api().get("courier");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = (data) => {
    data.start_date = formatDate(data.periode[0]);
    data.end_date = data.periode[1] ? formatDate(data.periode[1]) : formatDate(data.periode[0]);

    data.requirement_courier = JSON.stringify(data?.requirement_courier || []);
    data.requirement_products = JSON.stringify(data?.requirement_products || []);
    data.requirement_categories = JSON.stringify(data?.requirement_categories || []);
    if (!data.price) {
      delete data.price;
    }
    delete data.periode;

    const formData = new FormData();
    let file_key = ["files_thumbnail", "files", "files_mobile"];

    // if data is array then its gonna looop
    let array_key = [];

    if (images?.length) {
      let temp_image = [];
      for (let i = 0; i < images.length; i++) {
        temp_image.push(images[i].file);
      }
      data.files = temp_image;
    }

    if (!data.related?.length) {
      delete data.related;
    }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }
    crateMutate(formData);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Discount
          </h4>
        </div>
      </React.Fragment>
    );
  };
  const productSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.name} src={option?.images[0].url} />
        <div className="ml-2">{option.name}</div>
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Discount Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="title"
              render={({ field }) => (
                <InputText
                  placeholder="Input discount name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Discount Code : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="code"
              render={({ field }) => (
                <InputText
                  placeholder="Input discount code"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.code,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.code && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.description && (
              <small id="description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="type">Discount Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="type"
              render={({ field }) => <Dropdown className="w-full" emptyFilterMessage="no data found" value={field.value} options={DiscountTypeOptions} onChange={(e) => field.onChange(e)} optionLabel="label" optionValue="id" placeholder="Select type" display="chip" />}
            />
            {formState.errors?.type && (
              <small id="type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        {discount_type_watch === 5 ? (
          <div className="field col-12">
            <label htmlFor="requirement_categories">Requirement Category : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="requirement_categories"
                rules={{ required: false }}
                render={({ field }) => (
                  <MultiSelect
                    className={classNames({ "p-invalid": formState.errors?.requirement_categories }, "w-full")}
                    emptyFilterMessage="no data found"
                    disabled={!catData?.length}
                    value={field.value}
                    options={catData}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="_id"
                    placeholder="Select requirement category"
                    display="chip"
                  />
                )}
              />
              {formState.errors?.requirement_categories && (
                <small id="requirement_categories" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        ) : null}

        {discount_type_watch === 3 && (
          <div className="field col-12">
            <label htmlFor="requirement_products">Requirement Products : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="requirement_products"
                render={({ field }) => (
                  <MultiSelect
                    className="w-full"
                    emptyFilterMessage="no data found"
                    itemTemplate={productSelectTemplate}
                    disabled={!productOption?.length}
                    value={field.value}
                    options={productOption}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="_id"
                    placeholder="Select requirement products"
                    display="chip"
                  />
                )}
              />
              {formState.errors?.requirement_products && (
                <small id="requirement_products" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        )}

        {discount_type_watch === 4 || discount_type_watch === 6 ? (
          <div className="field col-12">
            <label htmlFor="requirement_courier">Requirement Courier : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="requirement_courier"
                render={({ field }) => (
                  <MultiSelect className="w-full" emptyFilterMessage="no data found" disabled={!productOption?.length} value={field.value} options={courierData} onChange={(e) => field.onChange(e)} optionLabel="name" optionValue="_id" placeholder="Select requirement products" display="chip" />
                )}
              />
              {formState.errors?.requirement_courier && (
                <small id="requirement_courier" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        ) : null}
        <div className="field col-12">
          <label htmlFor="limit_type">Limit Type : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="limit_type"
              render={({ field }) => <Dropdown className="w-full" emptyFilterMessage="no data found" value={field.value} options={DiscountUserTypeOptions} onChange={(e) => field.onChange(e)} optionLabel="label" optionValue="id" placeholder="Select limit type" display="chip" />}
            />
            {formState.errors?.limit_type && (
              <small id="limit_type" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="qty">Limit : </label>
          <Controller
            defaultValue={0}
            control={control}
            name="qty"
            render={({ field }) => (
              <InputNumber disabled={discount_type_limit_watch === 3} className={classNames({ "p-invalid": formState.errors?.qty }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />
            )}
          />
          {formState.errors?.qty && (
            <small id="qty" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="price">Use Percentage : </label> <br />
          <InputSwitch checked={isUsePercentage} onChange={(e) => setIsUsePercentage(e.value)} />
        </div>
        {isUsePercentage ? (
          <div className="field col-12">
            <label htmlFor="percentage">Percentage (%) : </label>
            <div className="w-full">
              <Controller defaultValue={0} control={control} name="percentage" render={({ field }) => <InputNumber className="w-full" onBlur={field.onBlur} ref={field.ref} value={field.value} max={100} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
              {formState.errors?.percentage && (
                <small id="percentage" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        ) : (
          <div className="field col-12">
            <label htmlFor="price">Dscount Price : </label>
            <Controller
              defaultValue={""}
              control={control}
              name="price"
              render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
            />
            {formState.errors?.price && (
              <small id="price" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        )}

        <div className="field col-12">
          <label htmlFor="requirement_price">Requirement Price : </label>
          <Controller
            defaultValue={""}
            control={control}
            name="requirement_price"
            render={({ field }) => <InputNumber className={classNames({ "p-invalid": formState.errors?.requirement_price }, "w-full")} onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />}
          />
          {formState.errors?.requirement_price && (
            <small id="requirement_price" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>

        <div className="field col-12 lg:col-6">
          <label htmlFor="periode">Discount Periode : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="periode"
              render={({ field }) => (
                <Calendar
                  showTime
                  hourFormat="24"
                  dateFormat="yy-mm-dd"
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  minDate={new Date()}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.description,
                    },
                    "w-full"
                  )}
                  placeholder="Select periode range"
                  selectionMode="range"
                  readOnlyInput
                />
              )}
            />
            {formState.errors?.periode && (
              <small id="periode" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="periode">For VIP : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="for_vip" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="periode">Auto Apply : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="auto_apply" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images: </label>
          <ReactImageUploading style={{ width: "100%" }} value={images} onChange={onChangeImage} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/discount">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(DiscountCreate, comparisonFn);
