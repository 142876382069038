// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { InputNumber } from "primereact/inputnumber";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import { Dropdown } from "primereact/dropdown";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import formatRupiah from "../../utils/formatRupiah";
import { useState } from "react";

const ListOrderUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // hooks
  const { control, handleSubmit, reset } = useForm();
  const [freebiesData, setFreebiesData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);

  // query
  const { data: transactionData } = useQuery(["transaction", id], () => getTransactionDetails());
  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/transaction/edit", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/list-of-order");
        toast.success("Transaction updated!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getTransactionDetails = async () => {
    try {
      const res = await Api().get("/transaction/detail/" + id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      let result = res.data.data;

      let freebies_data = result.free_gifts.filter((freebies) => freebies.gift_id.type === 1);
      let additional_data = result.free_gifts.filter((additional) => additional.gift_id.type === 2);

      setFreebiesData(freebies_data);
      setAdditionalData(additional_data);

      reset(result);
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const onSubmit = (data) => {
    updateMutate({
      status: data.status,
      transaction_id: data._id,
    });
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Update Transaction
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
      <div className="col-12">
        <Toolbar className="mb-4 w-full" left={leftToolbar} />
      </div>
      <TabView className="col-12 p-0 field">
        <TabPanel header="Transactions">
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Transaction ID : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="transaction_id"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 ">
            <label htmlFor="category_id">Transaction Status : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="status"
                render={({ field }) => (
                  <Dropdown
                    ref={field.ref}
                    optionLabel="label"
                    optionValue="id"
                    value={field.value}
                    onBlur={field.onBlur}
                    options={TransactionStatus}
                    className={classNames("w-full capitalize")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    placeholder="Choose category"
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Transaction Date : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="created_at"
                render={({ field }) => <InputText disabled value={NewTimeFormatter(field.value)} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Transaction Address">
          <section className="w-full grid">
            <div className="col-12">
              <p className="font-bold uppercase">Shipping Address</p>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="user_name">Reciever Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.user_name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Province : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.province"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">District : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.district"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Urban : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.urban"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">City : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.city"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Postal Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.postal_code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Phone Number : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.phone_number"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Email : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.email"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Country : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.country"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12">
              <label htmlFor="name">Address : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="shipping_address.address"
                  render={({ field }) => <InputTextarea rows={4} disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>

            <div className="col-12">
              <p className="font-bold uppercase">Billing Address</p>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="user_name">Reciever Name : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.user_name"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Province : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.province"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">District : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.district"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Urban : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.urban"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">City : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.city"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Postal Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.postal_code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Phone Number : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.phone_number"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Email : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.email"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Country : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.country"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12 lg:col-6">
              <label htmlFor="name">Code : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.code"
                  render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
            <div className="field col-12">
              <label htmlFor="name">Address : </label>
              <div className="w-full">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  defaultValue={""}
                  name="billing_address.address"
                  render={({ field }) => <InputTextarea rows={4} disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
                />
              </div>
            </div>
          </section>
        </TabPanel>
        <TabPanel header="Products">
          <p className="px-3">
            <span className="font-bold">PRODUCTS LIST</span>
          </p>
          <DataTable value={transactionData?.detail}>
            <Column
              body={(data) => (
                <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                  <Image imageClassName="object-contain w-full h-full" preview className="w-full h-full" src={data.product.images[0]?.url} alt="image" />
                </div>
              )}
              headerStyle={{ width: "150px", minWidth: "80px" }}
            ></Column>
            <Column field="product.name" header="Product Name" sortable></Column>
            <Column field="variant_index" header="Variant" body={(d) => d.product?.variant_detail[d.variant_index]?.variant.join(" - ")} sortable></Column>
            <Column field="qty" header="Qty" sortable></Column>
            <Column field="total_price" header="Price" body={(d) => formatRupiah(d.total_price)} sortable></Column>
            <Column headerStyle={{ width: "4rem" }}></Column>
          </DataTable>

          <div className="p-3">
            {freebiesData.length ? (
              <div className="">
                <p>
                  <span className="font-bold">FREEBIES PRODUCTS</span>
                </p>
                {freebiesData.map((freebies) => (
                  <DataTable key={freebies._id} value={freebies.products}>
                    <Column
                      body={(data) => {
                        console.log(data);
                        return (
                          <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                            <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.images[0]?.url} alt="image" />
                          </div>
                        );
                      }}
                      headerStyle={{ width: "150px", minWidth: "80px" }}
                    ></Column>
                    <Column field="name" header="Product Name"></Column>
                    <Column headerStyle={{ width: "4rem" }}></Column>
                  </DataTable>
                ))}
              </div>
            ) : null}

            {additionalData.length ? (
              <div className="mt-4">
                <span className="font-bold uppercase ">SELECTED Additional</span>
                <div>
                  {additionalData.map((additional) => (
                    <div className="mt-3 card">
                      <div className="font-medium uppercase"> {additional.gift_id.title}</div>
                      <div className=" flex mt-3">
                        <p style={{ width: "60px" }}>TEXT</p>
                        <p>
                          : <span className="ml-4">{additional.gift_id.content}</span>
                        </p>
                      </div>
                      <div className=" flex">
                        <p style={{ width: "60px" }}>PRICE</p>
                        <p>
                          : <span className="ml-4">{formatRupiah(additional.additional_price)}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </TabPanel>
        <TabPanel header="Shipping">
          <div className="field col-12 ">
            <label htmlFor="category_id">Shipping Status : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.status"
                render={({ field }) => (
                  <Dropdown
                    disabled
                    ref={field.ref}
                    optionLabel="label"
                    optionValue="id"
                    value={field.value}
                    onBlur={field.onBlur}
                    options={TransactionStatus}
                    className={classNames("w-full capitalize")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    placeholder="Choose category"
                  />
                )}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Courier : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.courier_id.name"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Service : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.type"
                render={({ field }) => <InputText disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
          <div className="field col-12 lg:col-12">
            <label htmlFor="sku">Shipping Price : </label>
            <div className="w-full">
              <Controller
                rules={{ required: true }}
                control={control}
                defaultValue={""}
                name="shipping_info.shipping_price"
                render={({ field }) => <InputNumber disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
              />
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div className="flex justify-content-center mt-4 w-full">
        <Button label="Save" loading={updateLoading} className=" p-button-primary mr-4" />
        <Link to="/dashboard/list-of-order">
          <Button type="button" label="Back" className=" p-button-secondary" />
        </Link>
      </div>
    </form>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(ListOrderUpdate, comparisonFn);
