// hooks
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Calendar } from "primereact/calendar";

// api related
import Api from "../../api/Api";

// utils
import { useNavigate } from "react-router-dom";
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import TransactionStatus from "../../assets/data/product_shipping_status.json";
import formatRupiah from "../../utils/formatRupiah";
import moment from "moment";

const ListOrder = ({ permissions }) => {
  // data limt will efect to page row and pagin
  let data_limit = 25;
  const navigate = useNavigate();

  // state
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });
  const [expandedRows, setExpandedRows] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [exportDates, setExportDates] = useState(null);

  // api calling
  const {
    data: transactionData,
    isLoading,
    refetch,
  } = useQuery(
    {
      queryKey: ["transaction", page],
      queryFn: () => getTransactions(page),
      keepPreviousData: true,
    },
    { initialData: [] }
  );

  // query
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/transaction", { data }), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Transaction Deleted!", { duration: 4000 });
      } catch (error) {
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  const getTransactions = async () => {
    try {
      const res = await Api().get(`/transaction?limit=${data_limit}&page=${page}`);
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      transaction_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.transaction_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      transaction_id: [data._id],
    };

    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const handleExport = async () => {
    try {
      if (!exportDates || !exportDates.length) {
        throw new Error("Please select date before export");
      }

      let startDate = moment(exportDates[0]).format("YYYY-MM-DD");
      let endDate = exportDates[1] ? moment(exportDates[1]).format("YYYY-MM-DD") : startDate;

      const response = await Api().get("/transaction/export", {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      var a = document.createElement("a");
      a.href = response.data.data;
      a.target = "_blank";
      a.click();
      setExportDates(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // child components
  const rowExpansionTemplate = (rowData) => {
    let freebies_data = rowData.free_gifts.filter((freebies) => freebies.gift_id.type === 1);
    let additional_data = rowData.free_gifts.filter((additional) => additional.gift_id.type === 2);

    return (
      <>
        <div className="p-3">
          <p>
            <span className="font-bold">PRODUCTS LIST</span>
          </p>
          <DataTable value={rowData.detail}>
            <Column
              body={(data) => (
                <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                  <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.product?.images[0]?.url} alt="image" />
                </div>
              )}
              headerStyle={{ width: "150px", minWidth: "80px" }}
            ></Column>
            <Column field="product.name" header="Product Name"></Column>
            <Column field="variant_index" header="Variant" body={(d) => d.product?.variant_detail[d.variant_index]?.variant.join(" - ") || "-"}></Column>
            <Column field="qty" header="Qty"></Column>
            <Column field="total_price" header="Price" body={(d) => formatRupiah(d.total_price)}></Column>
            <Column headerStyle={{ width: "4rem" }}></Column>
          </DataTable>
        </div>
        <div className="p-3">
          {freebies_data.length ? (
            <div className="">
              <p>
                <span className="font-bold">FREEBIES PRODUCTS</span>
              </p>
              {freebies_data.map((freebies) => (
                <DataTable key={freebies._id} value={freebies.products}>
                  <Column
                    body={(data) => {
                      console.log(data);
                      return (
                        <div style={{ maxWidth: "80px", maxHeight: "80px", overflow: "hidden" }}>
                          <Image preview imageClassName="object-contain w-full h-full" className="w-full h-full" src={data?.images[0]?.url} alt="image" />
                        </div>
                      );
                    }}
                    headerStyle={{ width: "150px", minWidth: "80px" }}
                  ></Column>
                  <Column field="name" header="Product Name"></Column>
                  <Column headerStyle={{ width: "4rem" }}></Column>
                </DataTable>
              ))}
            </div>
          ) : null}

          {additional_data.length ? (
            <div className="mt-4">
              <span className="font-bold uppercase ">SELECTED Additional</span>
              <div>
                {additional_data.map((additional) => (
                  <div className="mt-3 card">
                    <div className="font-medium uppercase"> {additional.gift_id.title}</div>
                    <div className=" flex mt-4">
                      <p style={{ width: "120px" }}>TEXT CONTENT</p>
                      <p>
                        : <span className="ml-4">{additional.gift_id.content}</span>
                      </p>
                    </div>
                    {additional.note ? (
                      <div className=" flex ">
                        <p style={{ width: "120px" }}>BUYER NOTE</p>
                        <p>
                          : <span className="ml-4">{additional.note}</span>
                        </p>
                      </div>
                    ) : null}
                    <div className=" flex ">
                      <p style={{ width: "120px" }}>PRICE</p>
                      <p>
                        : <span className="ml-4">{formatRupiah(additional.additional_price)}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {/* {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => navigate("/dashboard/product/create")} />} */}
          {permissions.delete && <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectItems.length} />}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {permissions.update && <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => navigate(`/dashboard/list-of-order/update/${rowData.transaction_id}`)} />}
        {permissions.delete && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />}
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="">
          <h5 className="m-0">Manage Transactions</h5>
        </div>

        <div className="flex align-items-end">
          <div className="flex flex-column">
            <label htmlFor="">Export Transaction</label>
            <Calendar className="mt-1" value={exportDates} onChange={(e) => setExportDates(e.value)} selectionMode="range" placeholder="Select date range" />
          </div>
          <div className="ml-2">
            <Button onClick={handleExport}>Export</Button>
          </div>
        </div>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card col-12 mx-auto">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              loading={isLoading}
              value={transactionData}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
              rowExpansionTemplate={rowExpansionTemplate}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              emptyMessage="No Data Found."
              header={header}
              responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
              <Column field="transaction_id" header="Transaction ID" headerStyle={{ width: "auto", minWidth: "5rem" }}></Column>
              <Column field="user.email" header="User email" body={(data) => data?.user?.email || data.guest_email} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
              <Column field="user.email" header="Reciever" body={(data) => data?.shipping_address?.user_name || data?.guest_name} headerStyle={{ width: "auto", minWidth: "2rem" }}></Column>
              <Column field="created_at" header="Transaction Date" body={(d) => NewTimeFormatter(d.created_at)} headerStyle={{ width: "10%", minWidth: "12rem" }}></Column>
              <Column field="shipping_price" header="Shipping" body={(d) => formatRupiah(d.shipping_price)} headerStyle={{ minWidth: "5rem" }}></Column>
              <Column field="total_payment" header="Total Payment" body={(d) => formatRupiah(d.total_payment)} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="status" className="capitalize" header="Status" body={(d) => TransactionStatus[d.status].label} headerStyle={{ width: "5%", minWidth: "3rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="ACTIONS" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "8rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} className="modal-container" header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} className="modal-container" header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Product?</span>}
        </div>
      </Dialog>

      {/* <DevTool control={updateForm.control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(ListOrder, comparisonFn);
