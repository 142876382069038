// hooks
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { Controller, useForm } from "react-hook-form";

// components
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

// utils
import classNames from "classnames";
import Api from "../../api/Api";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { InputSwitch } from "primereact/inputswitch";

const ActivateMemberPoint = ({ permissions }) => {
  const organizationID = process.env.REACT_APP_ORGANIZATION_ID;

  // React Query
  const { isLoading, mutate } = useMutation(async (data) => await Api().post("/organization/vip", data), {
    onSettled: (res, error) => {
      if (error) {
        return toast.error("Failed to change password");
      }

      if (res.data.status !== 200) {
        return toast.error(res.data.message);
      }

      toast.success("Password successfully changed");
      formHook.reset({});
      getDetails();
    },
  });

  // hooks
  const formHook = useForm();

  // functions
  const getDetails = async () => {
    try {
      const response = await Api().get(`/organization/${organizationID}`);

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      const data = response.data.data;
      formHook.reset({ activate_member_point: data?.activate_member_point, member_point_currency: data.member_point_currency });

      return response.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const changeUserDetailHandler = async (data) => {
    mutate(data);
  };

  useEffect(() => {
    getDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-fluid formgrid grid card col-12 mx-auto">
      <div className="col-12">
        <Toolbar
          className="mb-4 py-4"
          left={() => (
            <div className="">
              <h1 className="text-xl uppercase p-0 m-0">Activate Member Points</h1>
            </div>
          )}
        />
      </div>
      <div className="col-12">
        <form onSubmit={formHook.handleSubmit(changeUserDetailHandler)} className="p-fluid formgrid grid">
          {/* form */}
          <div className="field col-12 md:col-12">
            <label htmlFor="last_name">Member Point Currency :</label>
            <Controller
              rules={{ required: true }}
              control={formHook.control}
              defaultValue={0}
              name="member_point_currency"
              render={({ field }) => (
                <InputText
                  className={classNames({
                    "p-invalid": formHook.formState.errors.member_point_currency,
                  })}
                  ref={field.ref}
                  id="member_point_currency"
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  placeholder="input member point currency"
                />
              )}
            />
            {formHook.formState.errors.phone_number && (
              <small id="phone_number" className="p-error block pt-1">
                {formHook.formState.errors.phone_number.message}
              </small>
            )}
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="activate_member_point">Member Point Currency :</label>
            <Controller control={formHook.control} defaultValue={false} name="activate_member_point" render={({ field }) => <InputSwitch className="block" onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>

          <div className="flex justify-content-center mt-5 col-12 gap-1">
            <div className="mr-4">
              <Button loading={isLoading} label="Save Changes" className="p-button-success " />
            </div>
            <div className="">
              <Link to="/dashboard/products">
                <Button type="button" label="Back To Dashboard" className=" p-button-secondary" />
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActivateMemberPoint;
