// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import ReactImageUploading from "react-images-uploading";
import JoditEditor from "jodit-react";

// utils
import classNames from "classnames";
import JodithJournalConfig from "../../config/JodithJournalConfig";

// api related
import Api from "../../api/Api";
import { useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

const JournalCreate = () => {
  const journalType = [
    { value: 0, label: "Highlight" },
    { value: 1, label: "Default" },
  ];
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [imagesThumbnails, setImagesThumbnailes] = useState([]);

  // hooks
  const { control, handleSubmit, formState } = useForm();
  // const customProductWatch = watch("custom_product");

  // query
  // const { data: productOption } = useQuery("product", () => getProduct());
  const { data: journalOption } = useQuery("journal", () => getJournal());
  const { data: catData } = useQuery("product-category", () => getProductCategory(), { initialData: [] });
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("journal", data), {
    onSettled: async (response) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response?.data?.message || response?.data?.err);
        }
        navigate("/dashboard/journal");
        toast.success("Journal created!");
      } catch (error) {
        toast.error(error.message || "something went wrong");
      }
    },
  });

  // functions
  // const getProduct = async () => {
  //   try {
  //     const res = await Api().get("/product");

  //     if (res.data.status !== 200) {
  //       throw new Error(res.data.message);
  //     }

  //     return res.data.data;
  //   } catch (error) {
  //     toast.error(error.message);
  //     return error;
  //   }
  // };
  const getJournal = async () => {
    try {
      const res = await Api().get("/journal");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };
  const getProductCategory = async () => {
    try {
      const res = await Api().get("/journal-category");
      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onChangeImage = (imageList) => {
    setImages(imageList);
  };
  const onChangeImageThumbnails = (imageList) => {
    setImagesThumbnailes(imageList);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    let file_key = ["files_thumbnail", "files", "files_mobile"];

    // if data is array then its gonna looop
    let array_key = ["files", "files_thumbnail"];

    data.related = JSON.stringify(data.related);
    data.custom_product_list = JSON.stringify([]);

    if (!imagesThumbnails.length) return toast.error("Please Upload Image Thumbnail");
    if (imagesThumbnails.length) {
      let temp_image = [];
      for (let i = 0; i < imagesThumbnails.length; i++) {
        temp_image.push(imagesThumbnails[i].file);
      }
      data.files_thumbnail = temp_image;
    }

    if (!images.length) return toast.error("Please Upload Images");
    if (images.length) {
      let temp_image = [];
      for (let i = 0; i < images.length; i++) {
        temp_image.push(images[i].file);
      }
      data.files = temp_image;
    }

    if (!data.related?.length) {
      delete data.related;
    }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }

    crateMutate(formData);
  };

  const hanldeErrorImageUpload = (error) => {
    if (error?.acceptType) {
      toast.error("format image should jpg, jpeg, png");
    }

    if (error?.maxFileSize) {
      toast.error("File Size Max 1MB");
    }
    if (error?.maxNumber) {
      toast.error("max file is 1 images");
    }
  };

  // components
  // const productSelectTemplate = (option) => {
  //   return (
  //     <div className="flex align-items-center">
  //       <img width={50} alt={option.name} src={option?.images[0]?.url} />
  //       <div className="ml-2">{option.name}</div>
  //     </div>
  //   );
  // };
  const JournalSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img width={50} alt={option.title} src={option?.images[0]?.url} />
        <div className="ml-2">{option.title}</div>
      </div>
    );
  };

  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Journal
          </h4>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="field col-12 ">
          <label htmlFor="title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.title && (
              <small id="title" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="choose-kurir">Type :</label>
          <Controller
            rules={{ required: true }}
            control={control}
            name="type"
            render={({ field }) => (
              <Dropdown
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                options={journalType}
                optionLabel="label"
                optionValue="value"
                className={classNames({ "p-invalid": formState.errors.type }, "w-full")}
                onChange={(e) => {
                  field.onChange(e);
                }}
                placeholder="Select type"
              />
            )}
          />
          {formState.errors.type && (
            <small id="choose-kurir" className="p-error block pt-1">
              field required
            </small>
          )}
        </div>
        <div className="field col-12">
          <label htmlFor="category">Category : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="category"
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  ref={field.ref}
                  value={field.value}
                  onBlur={field.onBlur}
                  options={catData}
                  optionLabel="name"
                  optionValue="_id"
                  className={classNames({ "p-invalid": formState.errors.category }, "w-full")}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Select category"
                />
              )}
            />
            {formState.errors?.category && (
              <small id="category" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="related">Related Journal : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="related"
              render={({ field }) => (
                <MultiSelect
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!journalOption?.length}
                  value={field.value}
                  options={journalOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select related journal"
                  display="chip"
                  itemTemplate={JournalSelectTemplate}
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="small_text">Thumbnail Text : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="small_text"
              defaultValue={""}
              render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={formState.errors.small_text && "p-invalid"} style={{ width: "100%" }} placeholder="Input thumbnail text" autoResize rows="5" />}
            />
            {formState.errors.small_text && (
              <small id="choose-kurir" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="content">Content : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="content"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  // config={{ height: 350 }}
                  config={JodithJournalConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {formState.errors?.content && (
              <small id="content" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>

        {/* <div className="field col-12">
          <label htmlFor="custom_product">Add Product : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="custom_product" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>

        {customProductWatch ? (
          <div className="field col-12">
            <label htmlFor="custom_product_list">Product : </label>
            <div className="w-full">
              <Controller
                control={control}
                name="custom_product_list"
                render={({ field }) => (
                  <Dropdown
                    className="w-full"
                    emptyFilterMessage="no data found"
                    disabled={!productOption?.length}
                    value={field.value}
                    options={productOption}
                    onChange={(e) => field.onChange(e)}
                    optionLabel="name"
                    optionValue="_id"
                    placeholder="Select product"
                    itemTemplate={productSelectTemplate}
                  />
                )}
              />
              {formState.errors?.product && (
                <small id="product" className="p-error block pt-1">
                  field required
                </small>
              )}
            </div>
          </div>
        ) : null} */}

        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images Max 1MB (Thumbnails): </label>
          <ReactImageUploading style={{ width: "100%" }} maxNumber={1} maxFileSize={1000000} onError={hanldeErrorImageUpload} value={imagesThumbnails} onChange={onChangeImageThumbnails} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images Max 1MB / Image: </label>
          <ReactImageUploading style={{ width: "100%" }} maxNumber={1} maxFileSize={1000000} onError={hanldeErrorImageUpload} multiple value={images} onChange={onChangeImage} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-4 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="field col-12">
          <label htmlFor="active_status">Active Status : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/journal">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(JournalCreate, comparisonFn);
